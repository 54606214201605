import Button from 'devextreme-react/button';
import { Fragment, useCallback, useEffect, useState } from 'react';
import { t } from '../../localization/i18n';
import { confirm, dialog } from '../UI/Dialog';
import { useAjax } from "../../contexts/ajax";
import useHttp from "../../hooks/use-http";
import { getPdfDownloadSettings } from '../../model/song';
import * as constants from "../../lib/constants";


export default function BtnOffline( { onProgressChange, css }) {
  const { ajaxAction, ajaxRequest } = useAjax();
  const [progress, setProgress] = useState();

  //stáhne seznam všech skladeb, ke kterým má uživatel přístup
  const fetchData = useCallback(async () => {
    await ajaxAction("UserStatistics", null, "?%24orderby=Song%2FName&%24top=20&%24select=Id%2CRating%2CUsageCount%2CDateLastRecordUsed&%24expand=Song(%24select%3DId%2CName%2CInterpreter%2CDateInsert%2CObjectOwnerRefId)&%24filter=(Song%20ne%20null)%20and%20(Song%2FObjectOwnerRefId%20eq%2022)&%24count=true");
    await ajaxRequest("GET", "./js/pdf.worker.min.3.6.172.js", null, "text/javascript");

    let params = "?$select=Id,Name,MusicSheetRefId";
    return await ajaxAction("Songs", null, `GetUserSongs()${params}`);
    /// Aby to neřvalo, že potřebuje ajaxRequest i když ho má
    // eslint-disable-next-line
  }, [ajaxAction, ajaxRequest]);
  const { sendRequest: loadList, httpResponse} = useHttp(fetchData);

  //klik na tlačítko
  const btnClick = useCallback(() => {
    confirm.fire({
      title: t("offline"),
      text: t("confirmOffline")
    }).then(function(result) {
      if (result.value) {
        loadList();
      }
    });
  }, [loadList]);

  //pingne všechny playlisty a jejich obsah, aby se stáhly do uložiště
  const downloadPlaylists = useCallback(async () => {
    //seznam playlistů
    //TODO přesně stejné parametry musí kvůli offline režimu být i v MyPlaylistsList
    let playlists = await ajaxAction("Playlists", null, "?$orderby=Name&$select=Id,Name");
    await ajaxAction("UserStatistics", null, "?%24orderby=Playlist%2FName&%24top=20&%24select=Id%2CRating%2CUsageCount%2CDateLastRecordUsed&%24expand=Playlist(%24select%3DId%2CName%2CDateInsert%2CObjectOwnerRefId)&%24filter=(Playlist%20ne%20null)&%24count=true");

    //obsah playlistů
    for (const playlist of playlists?.value) {
      let params = "?$select=Id,Name";
      await ajaxAction("Playlists", playlist.Id, `GetSongsInPlaylist()${params}`);
    }
  }, [ajaxAction]);

  //pingne všechny písně, aby se stáhly do uložiště
  const downloadSongs = useCallback(async (songs) => {
      let count = 0;
      let total = songs.length;

      for (const song of songs) {
        //TODO přesně stejné parametry musí kvůli offline režimu být i v PlayerDetail
        await ajaxAction("Songs", song.Id, constants.OFFLINE_PARAMS_PLAYER_DETAIL);

        // Dotaz na SongDetail
        await ajaxAction("Songs", song.Id, constants.OFFLINE_PARAMS_SONG_DETAIL);
        if(song.MusicSheetRefId) {
          let settings = getPdfDownloadSettings(song);
          await ajaxRequest("GET", settings.url, null, "application/pdf");
        }
        count++;
        setProgress(parseInt(count * 100 / total));
      }
  }, [ajaxAction, ajaxRequest]);

  //zpracování odpovědi - seznam skladeb
  useEffect(() => {
    if(httpResponse?.value) {
      downloadPlaylists();
      downloadSongs(httpResponse.value);
    }
  }, [httpResponse, downloadSongs, downloadPlaylists]);

  //změna hodnoty progress baru
  useEffect(() => {
    if(progress === 100) {
      dialog.fire({
        icon: "success",
        title: t("offline"),
        text: t("offlineSuccess")
      });
    }
    onProgressChange(progress);
  }, [progress, onProgressChange]);

  return (
    <Fragment>
      <li className={`nav-item with-dx-button ${css ?? ""}`} >
        <Button
          id="btnOffline"
          icon={"fa-light fa-signal fa-fw"}
          onClick={btnClick}
          className={"btn btn-link nav-link ms-3"}
          text={t("offline")}
        />
      </li>
    </Fragment>
  );
}
