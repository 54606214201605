import React from 'react';
import App from './App';
import { createRoot } from 'react-dom/client';
import './index.css';
import './App.css';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register({
  //při zjištění, že je k dispozici nová verze cache, ji hned použít
  //jinak by se musel nejdřív zavřít prohlížeč a pak přenačíst stránka
  //(viz hláška "New content is available and will be used when all" v serviceWorkerRegistration)
  //https://stackoverflow.com/a/60263791
  onUpdate: registration => {
    if (registration && registration.waiting) {
      registration.waiting.postMessage({ type: 'SKIP_WAITING' });
    }
    window.location.reload();
  }
});

