export const messages_en = {
  webName: "Ebizon",

  dashboard: "Dashboard",
  player: "Player",
  songs: "Songs",
  playlists : "Playlists",
  bands: "Bands",
  messages: "Messages",
  profile: "Profile",
  settings: "Settings",


  ////////////
  posledni: ""
};
