import { NavLink } from 'react-router-dom';
import logo from "../../assets/logo-white.png";
import SearchForm from '../dashboard/SearchForm';
import { Fragment, useCallback, useState } from 'react';
import { t } from '../../localization/i18n';

export default function Header(props) {
  const [isMobileSearchVisible, setIsMobileSearchVisible] = useState(false);

  const handleToggleMobileSearch = useCallback(() => {
    setIsMobileSearchVisible(!isMobileSearchVisible);
  }, [isMobileSearchVisible]);

  return (
    <Fragment>
      <header className="navbar sticky-top d-flex p-0 align-items-center">
        <NavLink to='/' className="logo px-3 py-2">
          <img src={logo} height="50px" alt="logo" />
        </NavLink>

        <button className="d-block d-md-none navbar-toggler ms-auto" onClick={handleToggleMobileSearch} title={t("enterSearchTerm")}>
          <i className="fas fa-search"></i>
        </button>

        <button className="navbar-toggler collapsed d-md-none me-3" type="button" data-bs-toggle="collapse" data-bs-target="#sidebarMenu" aria-controls="sidebarMenu" aria-expanded="false" aria-label="Toggle navigation">
          <i className="fa-solid fa-xmark close" />
          <i className="fa-solid fa-bars open" />
        </button>

        <div className="d-none d-md-block ms-auto text-white me-3">
          <SearchForm height={40} />
        </div>
      </header>
      
      {isMobileSearchVisible && (
        <div className="mobile-search p-3 w-100 animated fadeInDown" style={{position: "fixed", top: "70px", background: "#002651", zIndex: "80"}}>
          <SearchForm height={40} isMobileSearch={true} handleSelectedItem={() => setIsMobileSearchVisible(false)} />
        </div>
      )}
    </Fragment>
  );
};
