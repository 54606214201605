import { t } from '../../localization/i18n';
import { useEffect, useState } from 'react';
import { useMatch, useParams } from 'react-router-dom';
import SidebarItem from '../layout/SidebarItem';

export default function  BtnHelp() {
  const { locale } = useParams();
  const [url, setUrl] = useState();
  const basicRoute = useMatch('/:locale/:module/*');
  const extendedRoute = useMatch('/:locale/:module/:action/*');

  //při startu zjistit, na jaké stránce jsme a podle toho sestavit odkaz na patřičnou nápovědu
  useEffect(() => {
    let entity = basicRoute?.params?.module ?? ""; //např. "songs"
    let action = extendedRoute?.params?.action ?? ""; //např. "edit"

    //neumíme rozlišit, jestli se jedná o routu pro detail záznamu ("songs/1"), nebo pro akci nad evidencí ("songs/edit")
    //v action je naplněno číslo - jedná se tedy o detail
    if(action !== undefined && !isNaN(parseFloat(action))) {
      action = "";
    }

    // songs        =>  http://localhost:3000/#/cs/how-to/songs
    // song/1       =>  http://localhost:3000/#/cs/how-to/song
    // song/edit/1  =>  http://localhost:3000/#/cs/how-to/song-edit
    let anchor = `${entity}${action ? `-${action}` : ""}`;
    setUrl(`/${locale}/how-to/${anchor}`);
  }, [basicRoute, extendedRoute, locale]);

  //na stránce "Jak používat Ebizon" není potřeba zobrazovat odkaz na nápovědu - už na ní jsme
  if(basicRoute?.params?.module === "how-to") {
    return null;
  }

  return <SidebarItem to={url} title={t("help")} icon={"fa-question-circle"} key="help" />
}
