import { Fragment, useEffect, useState } from 'react';
//import GeneralTerms from '../login/GeneralTerms';
import './LayoutSidebar.css';
//import { Link } from 'react-router-dom';
import { useLocalStorage } from '../../hooks/useStorage';
import Header from './Header';
import Sidebar from './Sidebar';
import { useLocation } from 'react-router-dom';
import { getActiveModuleNameFromUrl } from '../../lib/common';

export default function LayoutSidebar( props ) {
  const [isSidebarCompact, setIsSidebarCompact] = useLocalStorage("sidebar-compact", false);
  const location = useLocation();
  const [module, setModule] = useState();

  useEffect(() => {
    if(location) {
      let name = getActiveModuleNameFromUrl(location.pathname);
      setModule(name);
    }
  }, [location]);

  function handleToggleSidebarStyle(isCompact) {
    setIsSidebarCompact(isCompact);
  }

  return (
  <Fragment>
    <div className={isSidebarCompact ? "compact" : ""}>
      <Header />

      <div className="container-fluid">
        <div className="row">
          <Sidebar module={module} isCompact={isSidebarCompact} onToggleSidebarStyle={handleToggleSidebarStyle} />
          <main className={`px-2 px-md-4 pt-2 pt-md-4 ${isSidebarCompact ? "col" : "col-md-9 ms-sm-auto col-lg-10"}`}>
            {props.children}
          </main>
        </div>
      </div>
    </div>

    {/* <footer className="offset-md-3 offset-lg-2"> */}
    {/*
    <footer className={`${isSidebarCompact ? "" : "offset-md-3 offset-lg-2"}`}>
      <ul className="nav justify-content-center w-100">
        <li className=""><Link className="text-white" to={`/`}>O nás</Link></li>
        <li className=""><GeneralTerms /></li>
        <li className=""><Link className="text-white" to={`/`}>Kontakty</Link></li>
        <li className=""><Link className="text-white" to={`/`}>Prohlášení GDPR</Link></li>
      </ul>
    </footer>
    */}
  </Fragment>
  );
};
