import { Fragment } from 'react';
import Section from '../components/UI/Section';
import LoadingPanel from '../components/UI/LoadingPanel';

const EmptyPage = () => {
  return (
    <Fragment>
      <Section>
        <LoadingPanel visble={true} />
      </Section>
    </Fragment>
  )
};

export default EmptyPage;
