import ChordSheetJS from 'chordsheetjs';
import { convertStringToDateTime } from '../lib/common';
import { webApiUrl } from '../lib/odata';
import { convertUserFromOData } from './user';

let model = {
    //Všechny id položky by měly být null. Pokud se tady nenaplní a přesto se použijí v OData dotazu,
    //tak s null to nezhavaruje. Prázdný řetězec by vadil, protože OData tam očekává číslo, ne string.
    Id: null,
    Name: "",
    Interpreter: "",
    Content: "",
    HandwrittenNotes: "",
    IsPagingEnabled: false,
    OriginalContent: "",
    ScrollingSpeed: 0,
    ScrollingDelay: 0,
    MusicSheetRefId: "",
    Tempo: 120,
    Duration: 3.5 * 60 * 1000, //ms
    RehearseLink: "",
    DateInsert: "",
    ObjectOwner: null,
    Rating: 0,
    UsageCount: 0,
    DateLastRecordUsed: null
};

export const convertSongFromOData = function(data, loggedPerson) {
    let self = {};

    if(data.Id) {
        self.Id = data.Id;
    }
    if(data.Name) {
        self.Name = data.Name;
    }
    if(data.Interpreter) {
        self.Interpreter = data.Interpreter;
    }
    if(data.DateInsert) {
        self.DateInsert = convertStringToDateTime(data.DateInsert);
    }
    if(data.ObjectOwner) {
        self.ObjectOwner = convertUserFromOData(data.ObjectOwner);
    }
    if(data.Content) {
        self.Content = data.Content;
        self.OriginalContent = data.Content;
    }
    if(data.HandwrittenNotes) {
        self.HandwrittenNotes = data.HandwrittenNotes;
    }
    if(data.IsPagingEnabled) {
        self.IsPagingEnabled = data.IsPagingEnabled;
    }
    if(data.ScrollingSpeed) {
        self.ScrollingSpeed = data.ScrollingSpeed;
    }
    if(data.ScrollingDelay) {
        self.ScrollingDelay = data.ScrollingDelay;
    }
    if(data.MusicSheetRefId) {
        self.MusicSheetRefId = data.MusicSheetRefId;
    }
    if(data.Tempo) {
        self.Tempo = data.Tempo;
    }
    if(data.Duration) {
        
        self.Duration = data.Duration * 60 * 60 * 1000; //hod na ms
    }
    if(data.RehearseLink) {
        self.RehearseLink = data.RehearseLink;
        if(!self.RehearseLink.startsWith("http")) {
            self.RehearseLink = "http://" + self.RehearseLink;
        }
        self.RehearseLink = self.RehearseLink.replaceAll(" ", "");
    }
    if(data.UserStatistics && loggedPerson) {
        let statistics = data.UserStatistics.find(item => item.UserRefId === loggedPerson.User?.Id);
        if(statistics) {
            self.DateLastRecordUsed = convertStringToDateTime(statistics.DateLastRecordUsed);
            self.Rating = statistics.Rating;
            self.UsageCount = statistics.UsageCount;
        }
    }

    return {
        ...model,
        ...self
    };
}

export const convertStringToChordPro = function(text) {
    let chordpro = "";
    if(text) {
      const song = new ChordSheetJS.ChordsOverWordsParser().parse(text);
      chordpro = new ChordSheetJS.ChordProFormatter().format(song);
    }
    return chordpro;
}

export const getPdfDownloadSettings = function(song) {
    return {
        url: `${webApiUrl}document/GetDocumentContent/${song.MusicSheetRefId}`,
        //httpHeaders: { 'X-CustomHeader': '40359820958024350238508234' }
    };
}

export default model;
