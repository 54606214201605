import "./Section.css";
import React, {Fragment, useContext} from "react";
import { t } from "../../localization/i18n";
import { Link } from 'react-router-dom';
import {SmoothScrollContext} from "../../pages/player/SmoothScroll";

export default function Section({ icon, title, link, actions, id, css, isCompact, customElement, children, isDropdown, isPdfEdit, isFullscreen }) {
  let isHeadVisible = (!icon && !title && !actions) ? false : true;
  const {scrollPosition} = useContext(SmoothScrollContext);
  let titleEl = (
    <h2 className="title" data-cy={`SectionTitle`}>
      {icon && <span className="me-2"><i className={`fa-fw ${icon}`} /></span>}
      {title}
    </h2>
  );
  return (
    <Fragment>
      <div className={`section ${css ?? ""}`} id={id} data-cy={`Section-${id ?? ""}`}>
        {isHeadVisible &&
          <div
            style={isFullscreen ? {
              transform: `translate3d(0, ${scrollPosition - 78}px, 0)`,
              position: 'fixed',
              marginBottom: '-3.3rem',
              width: 'calc(100% + 3.9rem)',
              left: '-3rem',
              paddingRight: '3rem',
              // display: 'inline-flex',


            }: null}
            className={`head ${isCompact ? "compact" : ""} ${(isPdfEdit) ? "pdfEdit section-head-sticky-top" : null} ${isFullscreen ? "transformation" : null}`} >
            {title && link &&
              <Link to={link} >{titleEl}</Link>
            }
            {title && !link &&
              <Fragment>{titleEl}</Fragment>
            }
            {actions &&
              <Fragment>
                <div className="actions d-none d-lg-flex">
                  {actions}
                </div>
                <div className="actions d-flex d-lg-none">
                  {actions.map((button) => {
                    // console.log(button.props.isMobileView);
                    return (
                      button.props.isMobileView ? button : null
                    );
                })}
                </div>
              </Fragment>
            }

            {isDropdown && actions?.length &&
              <Fragment>
                <div className="dropdowns d-lg-none">
                  <button className="btn btn-outline-primary" type="button" title={t("moreActions")} data-bs-toggle="dropdown" aria-expanded="false">
                    <i className={"fa-solid fa-ellipsis-vertical fa-fw"}></i>
                  </button>
                  <ul className="dropdown-menu dropdown-menu-end">
                    {actions.map((button) => {
                      let dropdownItem = React.cloneElement(
                        button,
                        { isDropdown: true }
                      )
                      return <li key={button.key}>{dropdownItem}</li>
                    }
                    )}
                  </ul>
                </div>
              </Fragment>
            }

            {customElement}
          </div>
        }

        {children}

      </div>
    </Fragment>
  );
}
