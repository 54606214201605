import { Navigate, useParams } from "react-router-dom";
import { useAuth } from '../contexts/auth';
import LayoutSidebar from "./layout/LayoutSidebar";
import LoadingPanel from './UI/LoadingPanel';
import { useLocation } from 'react-router-dom';
import { ALLOWED_LANGS } from "../App";
 
export default function AppRoute({ component, isPrivate = true, layout }) {
  let location = useLocation();
  const { locale } = useParams();
  const { isAuthInProgress, loggedPerson } = useAuth();
  
  let page = null;
  if(layout) {
    page = layout({children: component}); //pro routu je zadán konkrétní layout
  }
  else {
    page = LayoutSidebar({children: component}); //layout není specifikován, použije se tedy výchozí
  }

  //nepodporovaný jazyk
  if(locale && !ALLOWED_LANGS.includes(locale)) {
    return <Navigate to="/" />;
  }

  //probíhá přihlášení
  if(isPrivate && isAuthInProgress) {
    return (
      <LoadingPanel visible={true} />
    );
  }

  //nepřihlášený uživatel
  if(isPrivate && !loggedPerson) {
    return <Navigate to={`/${locale}/login`} state={{ redirectTo: location }} replace={true} />;
  }

  //všechno OK, zobrazit stránku
  return page;
}
 