import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.softblue.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import '@fortawesome/fontawesome-pro/css/all.min.css';

import React, { Suspense, useEffect, useState } from 'react';
import { Routes, Route, Navigate, HashRouter, matchPath } from 'react-router-dom';
import AppRoute from './components/AppRoute';
import routes from './config/routes.js';
import { AuthProvider } from './contexts/auth';
import { AjaxProvider } from './contexts/ajax';

import { loadMessages, locale as dxLocale } from 'devextreme/localization';
import localization_dx_cs from "devextreme/localization/messages/cs.json";
import localization_dx_en from "devextreme/localization/messages/en.json";
import { messages_cs } from "./localization/messages.cs";
import { messages_en } from "./localization/messages.en";

import dxButton from 'devextreme/ui/button';
import LayoutSidebar from './components/layout/LayoutSidebar';
import EmptyPage from './pages/Empty';
import dxTextBox from 'devextreme/ui/text_box';

export const ALLOWED_LANGS = ["cs", "en"];

function App() {
  const [ lang, setLang ] = useState(ALLOWED_LANGS[0]);

  useEffect(() => {
    document.title = "Ebizon";

    //original DX překlady
    loadMessages(localization_dx_cs);
    loadMessages(localization_dx_en);
    //naše vlastní překlady, které mohou případně i přepsat originály od DX
    loadMessages({"cs": messages_cs});
    loadMessages({"en": messages_en});

    //aktuální routa je uložen v location.hash a začíná křížkem
    const pathname = window.location.hash.substring(1);
    //match path si s křížkem neporadí, proto se odsekává pryč
    const match = matchPath({ path: ":locale/*" }, pathname);
    let locale = match?.params?.locale;
    if(locale && ALLOWED_LANGS.includes(locale)) {
      setLang(locale);
    }
  }, []);

  useEffect(() => {
    //nastavení jazyka
    dxLocale(lang);
  }, [lang]);

  dxButton.defaultOptions({
    options: {
      activeStateEnabled: false,
      focusStateEnabled: false
    }
  })

  dxTextBox.defaultOptions({
    options: {
      labelMode: "floating"
    }
  })


  return (
    <AuthProvider>
      <AjaxProvider>
        <HashRouter>
          {/* aby během načítání stránka moc neblikala, vypadá schválně fallback jako normální stránka  */}
          <Suspense fallback={<LayoutSidebar><EmptyPage /></LayoutSidebar>}>
          <Routes>
            <Route path="/" element={<Navigate to={`/${lang}/dashboard`} replace />} />
            {routes.map((route) => (
              <Route
                exact={true}
                key={route.path}
                path={route.path}
                element={ <AppRoute component={route.component} isPrivate={route.isPrivate} layout={route.layout} /> }
              />
            ))}
          </Routes>
          </Suspense>
        </HashRouter>
      </AjaxProvider>
    </AuthProvider>
  );
}

export default App;
